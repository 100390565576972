let Validator = function () {};

/**
 * Validator object
 * @param value
 * @param rules
 * @param key
 * @returns {string}
 */
Validator.prototype.validate = function (value, rules, key) {
  let self = this;
  let msg = null;
  let endMessage = "";
  rules.forEach(function (rule) {
    msg = self[rule](value);
    if (msg) {
      endMessage = unCamelCase(key) + msg;
    }
  });
  return endMessage;
};

/**
 * check if value is string
 * @param value
 * @returns {string}
 */
Validator.prototype.isString = function (value) {
  if (typeof value === "string") {
    return "";
  }
  return " must be string!";
};

/**
 * Check if value is empty
 * @param value
 * @returns {string}
 */
Validator.prototype.isNotEmpty = function (value) {
  if (value !== "" && value !== null && typeof value !== "undefined") {
    return "";
  }
  return " is required!";
};

Validator.prototype.isLessThanTwenty = function (value) {
  if (
    value !== "" &&
    value !== null &&
    typeof value !== "undefined" &&
    value.length <= 20
  ) {
    return "";
  }
  return " must be less than 20!";
};
Validator.prototype.isLessThanFifty = function (value) {
  if (
    value !== "" &&
    value !== null &&
    typeof value !== "undefined" &&
    value.length <= 50
  ) {
    return "";
  }
  return " must be less than 50!";
};

Validator.prototype.isGreaterThanThree = function (value) {
  if (
    value !== "" &&
    value !== null &&
    typeof value !== "undefined" &&
    value >= 5
  ) {
    return "";
  }
  return " must be greater than 5!";
};
Validator.prototype.isGreaterThanSix = function (value) {
  if (
    value !== "" &&
    value !== null &&
    typeof value !== "undefined" &&
    value.length >= 6
  ) {
    return "";
  }
  return " must be greater than 5!";
};

/**
 * Check if value i integer
 * @param value
 * @returns {string}
 */
Validator.prototype.isInt = function (value) {
  if (!isNaN(value)) {
    return "";
  }
  return " is not a valid number!";
};

/**
 * Check if value i integer in select box
 * @param value
 * @returns {string}
 */
Validator.prototype.isIntSelect = function (value) {
  if (!isNaN(value)) {
    return "";
  }
  return " is required!";
};

/**
 *
 * @param value
 * @returns {string}
 */
Validator.prototype.isPositive = function (value) {
  if (value > 0) {
    return "";
  }
  return " cannot be negative or zero!";
};

/**
 *
 * @param value
 * @returns {string}
 */
Validator.prototype.isPassword = function (value) {
  if (
    value !== "" &&
    value !== null &&
    typeof value !== "undefined" &&
    value.length >= 6
  ) {
    return "";
  }
  return " length must be at least 6 characters!";
};

Validator.prototype.isIncludeNumber = function (value) {
  if (/\d/.test(value)) {
    return " cannot include numerical characters!";
  } else {
    return "";
  }
};

Validator.prototype.isAlphanumeric = function (value) {
  if (/^[a-zA-Z()]+$/.test(value)) {
    return "";
  } else {
    return " is not valid!";
  }
};

Validator.prototype.isZip = function (value) {
  if (/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(value)) {
    return " is not valid!";
  } else {
    return "";
  }
};

/**
 *
 * @param value
 * @returns {string}
 */
Validator.prototype.isNotIncludeSpecialCharacter = function (value) {
  let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  if (format.test(value)) {
    return " cannot include special characters!";
  } else {
    return "";
  }
};

/** check if value is in email format
 * @param value
 * @returns {string}
 */
Validator.prototype.isEmail = function (value) {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(value).toLowerCase())) {
    return "";
  } else {
    return " is not valid!";
  }
};

/**
 * check if value is true
 * @param value
 * @returns {string}
 */
Validator.prototype.isTrue = function (value) {
  if (value) {
    return "";
  } else {
    return " must be accepted!";
  }
};

/**
 * check if value is valid ethereum address
 * @param value
 * @returns {string}
 */
Validator.prototype.isAddress = function (value) {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(value)) {
    // check if it has the basic requirements of an address
    return " is not valid!";
  } else if (
    /^(0x)?[0-9a-f]{40}$/.test(value) ||
    /^(0x)?[0-9A-F]{40}$/.test(value)
  ) {
    // If it's all small caps or all all caps, return true
    return "";
  } else {
    return "";
  }
};

/**
 * change camel case to normal string
 * @param str
 * @returns {*}
 */
const unCamelCase = str => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
};

// any other rule you want to add

export { Validator };
