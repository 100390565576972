import { oauthApiUrl } from "../../services/api";
import axios from "axios";
import { LOAD_RATE, SET_COUPON } from "../../constants/types";
import { asyncEnd, asyncStart, dajngoValidationError } from "../Common";
import { addAlertMessage } from "../AlertMessage";
import { API_KEY } from "../../config/Auth";

const fetchRate = () => (dispatch, getState) => {
  let url = oauthApiUrl("billing/team/subscription-rate");
  // let url = 'https://cms.unplug.app/api/v1/billing/team/subscription-rate';
  let config = {
    headers: {
      "api-key": API_KEY,
    },
  };

  // let url = 'https://unplug.codefirm.net/billing/stripe/giftsubscriptionrate'
  return axios
    .get(url, config)
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: LOAD_RATE, payload: data.data });
      }
    })
    .catch(err => {
      dajngoValidationError(err, dispatch);
    });
};

const submitTeamForm = (card_token, recaptchaToken) => (dispatch, getState) => {
  let config = {
    headers: {
      "api-key": API_KEY,
    },
  };
  let url = oauthApiUrl("billing/enterprise/subscription");
  // let url = 'https://unplug.codefirm.net/billing/stripe/giftsubscriptionrate'
  let {
    email,
    password,
    company_name,
    maximum_users,
    card_holder,
    company_email,
    first_name,
    last_name,
  } = getState().teamPortal;

  return axios
    .post(
      url,
      {
        card_token,
        recaptchaToken,
        company_email,
        card_holder,
        company_name,
        first_name,
        last_name,
        email,
        password,
        maximum_users,
      },
      config
    )
    .then(({ data }) => {
      dispatch({ type: SET_COUPON, payload: data.coupon });
      let alert = {
        show: true,
        message: "Team successfully added!",
        event: "success",
      };
      dispatch(addAlertMessage(alert));
      dispatch(asyncEnd());
    })
    .catch(err => {
      dajngoValidationError(err, dispatch);
      dispatch(asyncEnd());
    });
};

export default {
  fetchRate,
  submitTeamForm,
};
