import React, { Component } from "react";
import phones from "../../../../assets/images/phones-transparent.png";
import logo from "../../../../assets/images/Black-logo.png";

export default class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copySuccess: false,
      baseUrl: "",
    };
    this._copyToClipboard = this._copyToClipboard.bind(this);
    this._clipboardBlur = this._clipboardBlur.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      let path =
        window.location.protocol + "//" + window.location.host + "/sign-up";
      this.setState({
        baseUrl: path,
      });
    }
  }

  _copyToClipboard = (text) => {
    var textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    this.setState({ copySuccess: true });
  };

  _clipboardBlur = (e) => {
    this.setState({ copySuccess: false });
  };
  refresh() {
    // window.location.reload();
    window.open("https://team.unplug.app/", "_blank");
  }

  render() {
    let { baseUrl } = this.state;
    let { coupon, name } = this.props;
    return (
      <React.Fragment>
        <div className="landing-page-container">
          <div className="landing-page-middle">
            <img className="landing-page-logo" src={logo} alt="" />
            <div className="kickOff-heading align-center-text">
              <p className="padding-10">Welcome to Unplug, {name}</p>
              <div className="kickOff-heading padding-5">
                <span>
                  Here is your Team's personalized redemption link that you can
                  distribute to team members to sign up for their Unplug app
                  subscription:
                </span>
              </div>
            </div>
            <div className="landing-page-link">
              <div className="link-container kickOff-price-per-user ">
                <p>
                  {baseUrl}/{coupon}
                </p>
              </div>
              <span
                onClick={(e) => this._copyToClipboard(`${baseUrl}/${coupon}`)}
              >
                {this.state.copySuccess ? `Copied` : "Copy Link"}
              </span>
            </div>
            <div className="kickOff-heading align-center-text padding-10">
              <span>
                You will also receive a confirmation email that includes your
                Team's personalized redemption link.
              </span>
            </div>
            <img src={phones} alt="phones" className="landing-page-phone" />
            <button className="kickOff-form-button" onClick={this.refresh}>
              Purchase More Plans
            </button>
            <div className="kickOff-help">
              <span>Need help?</span>{" "}
              <a
                className="underlined"
                href="mailto:support@unplug.com"
                target="_blank"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
