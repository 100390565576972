import React from "react";
import {connect} from "react-redux";
import {REMOVE_ALERT} from "../../constants/types";

class AlertDismissible extends React.Component {
	
	constructor(props, context) {
		super(props, context);
		this.state = {
			show: this.props.show
		};
	}
	
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.show) {
			setTimeout(function () {
				// this.props.handleDismiss(); // removed disapearing of msg after 5 seconds
			}.bind(this), 5000);
		}
	}
	
	render() {
		if (this.props.show) {
			return (
				<div className={`alert alert-dismissible fade show alert-position alert-${this.props.event} ${this.props.alertClass}`}
				     role="alert">
					{this.props.message}
					<button onClick={this.props.handleDismiss} type="button" className="close" data-dismiss="alert"
					        aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			);
		}
		return null;
		
		
	}
}

function mapDispatchToProps(dispatch) {
	return {
		handleDismiss: () => {
			dispatch({type: REMOVE_ALERT});
		}
		
	};
}

const mapStateToProps = state => ({
	...state.alert
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertDismissible);