import {
	ADD_VALIDATION_ERROR_OBJ,
	ASYNC_END,
	ASYNC_START,
	REDIRECT_TO,
	UPDATE_FIELD_AUTH,
	USER_CAPACITY_FULL
} from "../../constants/types";
import { addAlertMessage } from "../AlertMessage";

/**
 * async start
 * @returns {{type: string}}
 */
export function asyncStart() {
	return {
		type: ASYNC_START
	};
}

/**
 * async end
 * @returns {{type: string}}
 */
export function asyncEnd() {
	return {
		type: ASYNC_END
	};
}

/**
 * Redirect
 * @param url
 * @returns {{redirectTo: *, type: string}}
 */
export function redirectTo(url) {
	return {
		type: REDIRECT_TO,
		redirectTo: url
	};
}

/**
 * Add validation error
 * @param data
 * @returns {{type: string, error: *}}
 */
export function addValidationError(data) {
	return {
		type: ADD_VALIDATION_ERROR_OBJ,
		error: data

	};
}

export function dajngoValidationError(error, dispatch) {

	if (error.message === 'Network Error') {
		dispatch({ type: REDIRECT_TO, redirectTo: '/not-found' });
		return;
	}
	
	let err = error.response.data, errorData = {};
	if (err.error) {
		if (err.error === "Team capacity already fulfilled") {
			dispatch({ type: UPDATE_FIELD_AUTH, key: 'team_name', value: err.team_name });
			dispatch({ type: USER_CAPACITY_FULL, payload: true });
		} else {
			dispatch(addAlertMessage({ show: true, message: err.error, event: "danger", autoDismiss: true }));
		}
	} else {
		Object.keys(err).map((val) => {
			return errorData[val] = err[val][0];
		});
		dispatch(addValidationError(errorData));
	}
}

export function returnErrorOrValidation(error, dispatch) {
	let alert = error.response.data.message;
	if (alert) {
		dispatch(addAlertMessage({ show: true, message: alert, event: "error", autoDismiss: true }));
	} else {
		let data = error.response.data.error;
		dispatch(addValidationError(data));
	}
}


/**
 * Delete validation key
 * @param keyname
 * @returns {Function}
 */
const deleteValidationErrorAttribute = (keyname) => (dispatch, getState) => {
	let { validationError } = getState();
	if (validationError) {
		let errorObj = validationError.error;
		if (errorObj && errorObj[keyname]) {
			delete errorObj[keyname];
			dispatch(addValidationError(errorObj));
		}
	}

};

/**
 * Export default
 */
export default {
	deleteValidationErrorAttribute,
};