import { oauthApiUrl } from '../../services/api';
import { ADD_VALIDATION_ERROR_OBJ, APP_LOAD, LOGIN, UPDATE_FIELD_AUTH } from "../../constants/types";
import axios from 'axios';
import { asyncEnd, asyncStart, dajngoValidationError } from "../Common";
import { checkError } from "../../components/Helper/model";
import { API_KEY } from "../../config/Auth"

export function setAccessToken(data) {
    return {
        type: LOGIN,
        payload: data,
        error: !data
    };
}

export function setProfile(data) {
    tokenPlugin();
    return {
        type: APP_LOAD,
        payload: data.data,
        appLoaded: true
    };

}

let token;

const tokenPlugin = () => {
    if (token) {
        return {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
    }
    return {};
};

export function addValidationError(data) {
    return {
        type: ADD_VALIDATION_ERROR_OBJ,
        error: data

    };
}

const registerUser = () => (dispatch, getState) => {
    dispatch(asyncStart());
    let { email, password, first_name, last_name, coupon, agreement } = getState().auth;
    let model = {
        email: {
            value: email,
            key: "email",
            validator: ['isEmail', 'isNotEmpty']
        },
        password: {
            value: password,
            key: "password",
            validator: ['isNotEmpty']
        },
        first_name: {
            value: first_name,
            key: "first name",
            validator: ['isAlphanumeric', 'isNotEmpty']
        },
        last_name: {
            value: last_name,
            key: "last name",
            validator: ['isAlphanumeric', 'isNotEmpty']
        },
        coupon: {
            value: coupon,
            validator: ['isNotEmpty']
        },
        agreement: {
            value: agreement,
            key: "agreement",
            validator: ['isTrue']
        }
    };
    let err = checkError(model);
    if (Object.keys(err).length > 0) {
        dispatch(addValidationError(err));
        dispatch(asyncEnd());
        return;
    }
    let config = {
        headers: {
            'api-key': API_KEY,
        }
    }
    const url = oauthApiUrl('auth/register');
    // let url = 'https://dev-cms.unplug.app/api/v1/auth/register';
    return axios.post(`${url}`, { email, password, first_name, last_name, coupon, agreement }, config)
        .then(({ data }) => {
            dispatch({ type: UPDATE_FIELD_AUTH, key: 'team_name', value: data.data.team_name });
            dispatch({ type: UPDATE_FIELD_AUTH, key: 'registerSuccessFul', value: true });
            dispatch(asyncEnd());
        }).catch((err) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            dajngoValidationError(err, dispatch);
            dispatch(asyncEnd());
        });
};

export default {
    registerUser,
    setToken: _token => {
        token = _token;
    }
};