export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const LOGOUT = 'LOGOUT';
export const ASYNC_END = 'ASYNC_END';
export const APP_LOAD = 'APP_LOAD';
export const REDIRECT_CLEAR = 'REDIRECT_CLEAR';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const REDIRECT_TO = 'REDIRECT_TO';
export const ADD_VALIDATION_ERROR_OBJ = 'ADD_VALIDATION_ERROR_OBJ';
export const UNLOAD_VALIDATOR = 'UNLOAD_VALIDATOR';
export const UNLOAD_VALIDATION_ERROR = 'UNLOAD_VALIDATION_ERROR';


export const UPDATE_FIELD_TEAM = 'UPDATE_FIELD_TEAM';
export const LOAD_RATE = 'LOAD_RATE';
export const SET_COUPON = 'SET_COUPON';
export const USER_CAPACITY_FULL = 'USER_CAPACITY_FULL';
export const UNLOAD_TEAM_PORTAL = 'UNLOAD_TEAM_PORTAL';
