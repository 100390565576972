import React, { Component } from "react";
import { main_app } from "../../../../config/Auth";
import logo from "../../../../assets/images/Black-logo.png";
import yogaBanner from "../../../../assets/images/yoga-banner.png";
import appStore from "../../../../assets/images/app-store.png";
import playStore from "../../../../assets/images/google-app.png";
import Exclamation from "../../../../assets/images/icon-exclamation.svg";

export default class Kickoff extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <Header/> */}
        <div class="wrapper welcome-wrapper d-flex">
          <div class="left-div">
            <div class="left-banner-img">
              <img src={yogaBanner} alt="" class="img-fluid"></img>
              <div class="left-logo-div">
                <img src={logo} class="img-fluid" alt=""></img>
              </div>
            </div>
          </div>

          <div class="right-div text-center ">
            <div class="right-div-content">
              <div class="right-text-div ">
                <h2>Welcome to Unplug</h2>
                <h3>
                  For the full unplug experience, download our mobile app and
                  login
                </h3>
              </div>
              <div>
                <div className="right-bubble">
                  <img
                    className="exclamation-icon"
                    src={Exclamation}
                    alt="exclamation icon"
                  ></img>

                  <span>
                    Once in the app, if you see a screen with subscription
                    plans, simply X out in the top right corner, then login.
                  </span>
                </div>
              </div>
              <div class="app-icon-btn  ">
                <div class="app-icon-app">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/us/app/unplug-meditation/id1188080269#?platform=iphone"
                  >
                    <img src={appStore} class="img-fluid " alt=""></img>
                  </a>
                </div>
                <div class="app-icon-google">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.unplug.meditation"
                  >
                    <img src={playStore} class="img-fluid " alt=""></img>
                  </a>
                </div>
              </div>
              <div className="web-link d-flex justify-content-center">
                OR{" "}
                <p className="ml-1">
                  {" "}
                  MEDITATE ON{" "}
                  <a target="_blank" href={main_app + "login"}>
                    UNPLUG.APP
                  </a>
                </p>
              </div>

              <div className="text-center kickoff-contact">
                <p>
                  Need help? &nbsp;
                  <a href="mailto:support@unplug.com">
                    <u>Contact us</u>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
