import {ADD_VALIDATION_ERROR_OBJ, UNLOAD_VALIDATION_ERROR, UNLOAD_VALIDATOR} from "../constants/types";

/**
 * validation reducer
 * @param state
 * @param action
 * @returns {*}
 */
export default function (state = {error: {}}, action) {

    switch (action.type) {
        case UNLOAD_VALIDATOR:
            return {};
        case ADD_VALIDATION_ERROR_OBJ:
            return {...state, error: action.error};
        case UNLOAD_VALIDATION_ERROR:
            return {...state, error: null};
        default:
            return state;
    }
}