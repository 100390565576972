import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import App from "./components/App";
import { history, store } from "./store";
import * as serviceWorker from "./serviceWorker";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import dotenv from "dotenv";
import "./interceptors";
import { StripeProvider } from "react-stripe-elements";
import { stripekey } from "./config/Auth";

function render(App) {
  ReactDOM.render(
    <StripeProvider apiKey={stripekey}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </StripeProvider>,

    document.getElementById("root")
  );
}

dotenv.config();
render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
