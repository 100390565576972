let authKey = {}, apiUrl = "", main_app = "", liveapiURL = "", stripekey = "";

if (process.env.REACT_APP_DEPLOYMENT === "dev") {
  authKey = {
    "client_id": parseInt(process.env.REACT_APP_CLIENT_ID_DEV),
    "client_secret": process.env.REACT_APP_CLIENT_SECRET_DEV
  };
  apiUrl = process.env.REACT_APP_CLIENT_URL_DEV;
  main_app = process.env.REACT_APP_MAIN_APP_URL_DEV;
  liveapiURL = process.env.REACT_APP_MAIN_APP_URL_DEV;
  stripekey = process.env.REACT_APP_STRIPE_KEY_DEV;

} else if (process.env.REACT_APP_DEPLOYMENT === "prod") {
  authKey = {
    "client_id": parseInt(process.env.REACT_APP_CLIENT_ID_PROD),
    "client_secret": process.env.REACT_APP_CLIENT_SECRET_PROD
  };
  apiUrl = process.env.REACT_APP_CLIENT_URL_PROD;
  main_app = process.env.REACT_APP_MAIN_APP_URL_PROD;
  liveapiURL = process.env.REACT_APP_APP_URL_PROD;
  stripekey = process.env.REACT_APP_STRIPE_KEY_PROD;

} else {
  authKey = {
    "client_id": parseInt(process.env.REACT_APP_CLIENT_ID_QA),
    "client_secret": process.env.REACT_APP_CLIENT_SECRET_QA
  };
  apiUrl = 'https://dev-cms.unplug.app/api/v1/';
  main_app = 'https://www.unplug.com/';
  liveapiURL = 'https://dev.unplug.app/'
}

const GOOGLE_RECAPTCHA = process.env.REACT_APP_RECAPTCHA;
const API_KEY = process.env.REACT_APP_API_KEY;

export {
  authKey, apiUrl, stripekey, main_app, liveapiURL, API_KEY, GOOGLE_RECAPTCHA
};
