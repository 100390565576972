import React, { Component } from "react";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from "react-stripe-elements";
import { checkError } from "../../../Helper/model";
import Loading from "../../../Layout/Loading";
import { Row, Col } from "reactstrap";
import { GOOGLE_RECAPTCHA } from "../../../../config/Auth";
import { connect } from "react-redux";
import { asyncStart, asyncEnd } from "../../../../actions/Common";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updated: false,
      card_number: "",
      cvc: "",
      expiry: "",
      elementStyle: {
        fontSize: "",
        padding: "",
      },
    };
    this.handleChange = ev =>
      this.props.onChangeField(ev.target.name, ev.target.value);
    this.submitForm = () => async ev => {
      ev.preventDefault();
      let { company_email, card_holder, company_name } = this.props;
      let { card_number, cvc, expiry, accepted } = this.state;
      let model = {
        company_email: {
          value: company_email,
          key: "Company Email",
          validator: ["isEmail", "isNotEmpty"],
        },
        card_holder: {
          value: card_holder,
          key: "card Holder's Name",
          validator: ["isNotEmpty"],
        },
        company_name: {
          value: company_name,
          key: "Company Name",
          validator: ["isNotEmpty"],
        },
        expiry: {
          value: expiry,
          key: "Expiry Date",
          validator: ["isNotEmpty"],
        },
        cvc: {
          value: cvc,
          key: "Cvc",
          validator: ["isNotEmpty"],
        },
        card_number: {
          value: card_number,
          key: "card number",
          validator: ["isNotEmpty"],
        },
        accepted: {
          value: accepted,
          key: "",
          validator: ["isTrue"],
        },
      };
      let err = checkError(model);
      if (Object.keys(err).length > 0) {
        this.props.onAddValidationError(err);
        return;
      }
      this.props.dispatch(asyncStart());

      await this.props.stripe
        .createToken({ name: card_holder })
        .then(({ token, error }) => {
          if (error) {
            if (error.type !== "validation_error") {
              let alert = {
                show: true,
                message: error.message,
                event: "danger",
              };
              this.props.onAddAlert(alert);
            }
            this.props.dispatch(asyncEnd());
          } else {
            function callCaptcha(func) {
              window.grecaptcha.ready(function () {
                window.grecaptcha
                  .execute(GOOGLE_RECAPTCHA, {
                    action: "submit",
                  })
                  .then(function (recaptchaToken) {
                    if (recaptchaToken !== undefined) {
                      func.props.onSubmitTeamRegistration(
                        token.id,
                        recaptchaToken
                      );
                    }
                  });
              });
            }
            callCaptcha(this);
          }
        });
    };

    this.captureError = this.captureError.bind(this);
  }
  handleResize = () => {
    if (window.innerHeight <= 600 && window.innerWidth <= 1280) {
      this.setState({
        elementStyle: {
          ...this.state.elementStyle,
          fontSize: "10px",
          padding: "10px 14px",
        },
      });
    }

    if (window.innerWidth <= 1280 && window.innerHeight > 600) {
      this.setState({
        elementStyle: {
          ...this.state.elementStyle,
          fontSize: "14px",
          padding: "10px 14px",
        },
      });
    }
    if (window.innerWidth >= 1280) {
      this.setState({
        elementStyle: {
          ...this.state.elementStyle,
          fontSize: "16px",
          padding: "10px 14px",
        },
      });
    }
    if (window.innerHeight <= 700) {
      this.setState({
        elementStyle: {
          ...this.state.elementStyle,
          fontSize: "9px",
          padding: "9px 10px",
        },
      });
    }
  };

  captureError(ev, key) {
    let error = this.props.error;
    delete error[key];
    this.props.onAddValidationError(error);

    if (ev.error && ev.error.message) {
      error[key] = ev.error.message;

      this.props.onAddValidationError(error);
    }
    if (!ev.empty) {
      this.setState({
        [key]: "value",
      });
    } else {
      this.setState({
        [key]: "",
      });
    }
    this.setState({
      update: true,
    });
  }
  componentDidMount() {
    // if (window.innerHeight <= 600 && window.innerWidth <= 1280) {
    //   this.setState({
    //     elementStyle: {
    //       ...this.state.elementStyle,
    //       fontSize: "10px",
    //     },
    //   });
    // }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.handleResize();
  }

  render() {
    let { card_holder, company_name, company_email } = this.props;
    const styles = {
      base: {
        fontSize: this.state.elementStyle.fontSize,
        textTransform: "capitalize",
        fontWeight: "100",
        backGround: "#E7F6FF",
        padding: this.state.elementStyle.padding,
        "::placeholder": {
          fontWeight: "10",
          color: "#AFAFAF",
        },
      },
    };
    return (
      <>
        <form onSubmit={this.submitForm} className="kickOff-user-details">
          <Row className="kickOff-form-row-full">
            <Col className="kickOff-form-input-full" xs={12}>
              <div className="kickOff-label-container">
                <span className="kickOff-label">Company / Team Name</span>
              </div>
              <input
                type="text"
                className={`kickOff-input ${
                  this.props.checkIfValidationError("company_name")
                    ? "kickOff-input-error"
                    : ""
                }`}
                placeholder="Company/Team Name"
                name="company_name"
                value={company_name}
                onChange={this.handleChange}
                disabled={this.props.inProgress}
              />
              <span className="kickOff-error-full">
                {this.props.checkIfValidationError("company_name")}
              </span>
            </Col>
          </Row>
          <Row className="kickOff-form-row-full">
            <Col className="kickOff-form-input-full" xs={12}>
              <div className="kickOff-label-container">
                <span className="kickOff-label">
                  Company Email (to send confirmation)
                </span>
              </div>
              <input
                type="text"
                className={`kickOff-input ${
                  this.props.checkIfValidationError("company_email")
                    ? "kickOff-input-error"
                    : ""
                }`}
                placeholder="Company Email (to send confirmation)"
                name="company_email"
                value={company_email}
                onChange={this.handleChange}
                disabled={this.props.inProgress}
              />
              {this.props.checkIfValidationError("company_email") && (
                <span className="kickOff-error-full">
                  {this.props.checkIfValidationError("company_email")}
                </span>
              )}
            </Col>
          </Row>
          <Row className="kickOff-form-row-full">
            <Col className="kickOff-form-input-full" xs={12}>
              <div className="kickOff-label-container">
                <span className="kickOff-label">Full Name on Card</span>
              </div>
              <input
                type="text"
                className={`kickOff-input ${
                  this.props.checkIfValidationError("card_holder")
                    ? "kickOff-input-error"
                    : ""
                }`}
                placeholder="Full Name on Card"
                name="card_holder"
                value={card_holder}
                onChange={this.handleChange}
                disabled={this.props.inProgress}
              />
              <span className="kickOff-error-full">
                {this.props.checkIfValidationError("card_holder")}
              </span>
            </Col>
          </Row>

          <Row className="kickOff-form-row-full">
            <Col className="kickOff-form-input-full" xs={12}>
              <div className="kickOff-label-container">
                <span className="kickOff-label">Card Number</span>
              </div>
              <CardNumberElement
                style={styles}
                onChange={ev => this.captureError(ev, "card_number")}
                className={`${
                  this.props.checkIfValidationError("card_number")
                    ? "step3-stripe-error"
                    : ""
                } step3-stripe`}
                disabled={this.props.inProgress}
              />
              <span className="kickOff-error-full">
                {this.props.checkIfValidationError("card_number")}
              </span>
            </Col>
          </Row>
          <Row className="row-full">
            <Col className="kickOff-form-input expiry-style" xs={6}>
              <div className="kickOff-label-container">
                <span className="kickOff-label">Exp. Date</span>
              </div>
              <CardExpiryElement
                style={styles}
                onChange={ev => this.captureError(ev, "expiry")}
                className={`${
                  this.props.checkIfValidationError("expiry")
                    ? "step3-stripe-error"
                    : ""
                } 
                   step3-stripe`}
                disabled={this.props.inProgress}
              />
              <span className="kickOff-error-full">
                {this.props.checkIfValidationError("expiry")}
              </span>
            </Col>
            <Col className="kickOff-form-input  cvc-style" xs={6}>
              <div className="kickOff-label-container">
                <span className="kickOff-label">CVC</span>
              </div>
              <CardCVCElement
                style={styles}
                onChange={ev => this.captureError(ev, "cvc")}
                className={`${
                  this.props.checkIfValidationError("cvc")
                    ? "step3-stripe-error"
                    : ""
                } 
                   step3-stripe`}
                disabled={this.props.inProgress}
              />
              <span className="kickOff-error-full">
                {this.props.checkIfValidationError("cvc")}
              </span>
            </Col>
          </Row>
          <div className="kickOff-terms">
            <input
              type="checkbox"
              checked={this.state.accepted}
              disabled={this.props.inProgress}
              onChange={ev => {
                this.captureError(ev, "accepted");
                this.setState({
                  ...this.state,
                  accepted: ev.target.checked,
                });
              }}
            />
            <p
              className={`${
                this.props.checkIfValidationError("accepted")
                  ? "checkbox-validation"
                  : ""
              } kickOff-terms-text`}
            >
              By checking this box, you agree to Unplug's{" "}
              <a href="https://www.unplug.com/terms-of-use" target="_blank">
                {" "}
                Terms of Use
              </a>{" "}
              and to be automatically billed for your first year of Unplug's
              Team Plan, which will then automatically renew every year until
              canceled.{" "}
              <a
                href="mailto:support@unplug.com"
                className="underlined"
                target="_blank"
              >
                Contact Unplug
              </a>{" "}
              to manage your subscription.
              {/* By checking this box, you agree to Unplug's{" "}
              <a href="https://www.unplug.com/terms-of-use" target="_blank">
                {" "}
                Terms of Use
              </a>
              {"  "}
              and to billed for your first year of Unplug's Team Plan, which
              will then automatically renew every year until Contact to manage
              your subscription. */}
            </p>
          </div>

          <button className="kickOff-form-button" onClick={this.submitForm()}>
            <Loading
              inProgress={this.props.inProgress}
              content={"Place Your Order"}
            />
          </button>
        </form>
      </>
    );
  }
}

export default connect()(injectStripe(CheckoutForm));
