import React, { Component } from "react";
import { connect } from "react-redux";
import { common, teamPortal } from "../../../actions";
import { bindActionCreators } from "redux";
import { Row, Col } from "reactstrap";
import {
  ADD_ALERT,
  ADD_VALIDATION_ERROR_OBJ,
  UNLOAD_TEAM_PORTAL,
  UPDATE_FIELD_TEAM,
} from "../../../constants/types";
import StripeForm from "./StripeForm";
import LandingPage from "./LandingPage";
import logo from "../../../assets/images/Black logo.png";
import girlAtDesk from "../../../assets/images/girlAtDesk.png";
import back from "../../../assets/images/back-arrow.svg";
import { checkError } from "../../Helper/model";
import brandOne from "../../../assets/images/trust-companies1.webp";
import brandTwo from "../../../assets/images/trust-companies2.webp";
import { Elements } from "react-stripe-elements";
import AlertDismissible from "../../Alert";
import { calculateDiscountedPrice } from "../../../utils/discount.util";
import { withRouter } from "react-router-dom";
import Marquee from "react-fast-marquee";

const mapStateToProps = state => ({
  ...state.home,
  ...state.common,
  ...state.alert,
  ...state.teamPortal,
  ...state.validationError,
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => bindActionCreators(teamPortal.fetchRate, dispatch)(),
  onUnLoad: () => dispatch({ type: UNLOAD_TEAM_PORTAL }),
  onSubmitTeamRegistration: (submitTeamForm, recaptchaToken) =>
    bindActionCreators(teamPortal.submitTeamForm, dispatch)(
      submitTeamForm,
      recaptchaToken
    ),
  onAddValidationError: data =>
    dispatch({ type: ADD_VALIDATION_ERROR_OBJ, error: data }),
  onAddAlert: alert =>
    dispatch({
      type: ADD_ALERT,
      show: alert.show,
      message: alert.message,
      event: alert.event,
    }),
  onChangeField: (key, value) => {
    dispatch({ type: UPDATE_FIELD_TEAM, key, value });
    bindActionCreators(common.deleteValidationErrorAttribute, dispatch)(key);
  },
});

class TeamPortal extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.sliderChangeHandler = this.sliderChangeHandler.bind(this);
    this.captureError = this.captureError.bind(this);
    this.state = {
      newYearlyCharge: 0,
      oldYearlyCharge: 0,
      teamUsers: 0,
      step: 1,
      updated: false,
      card_number: "",
      cvc: "",
      expiry: "",
      accepted: false,
      discountPercentage: 10,
    };
    this.handleChange = ev =>
      this.props.onChangeField(ev.target.name, ev.target.value);
  }

  componentWillMount() {
    this.props.onLoad();
  }

  componentDidMount() {
    const marqueeImages = document.getElementsByClassName("marquee-image");
    for (let i = 0; i < marqueeImages.length; i++) {
      marqueeImages[i].style.display = "block";
    }
    const queryParams = new URLSearchParams(this.props.location.search);
    const stepFromQuery = queryParams.get("page");
    if (stepFromQuery) {
      if (stepFromQuery < 4) {
        this.setState({ ...this.state, step: Number(stepFromQuery) });
      }
    } else {
      this.setState({ ...this.state, step: 1 });
    }
  }
  componentWillUnmount() {
    this.props.onUnLoad();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.rate && nextProps.maximum_users === 5) {
      let newYearlyCharge = parseFloat(
        calculateDiscountedPrice(nextProps.rate.old_rate, 10) *
          this.props.maximum_users
      ).toFixed(2);
      let oldYearlyCharge = parseFloat(
        nextProps.rate.old_rate * this.props.maximum_users
      ).toFixed(2);
      this.setState({
        newYearlyCharge,
        oldYearlyCharge,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.maximum_users !== prevProps.maximum_users &&
      this.props.maximum_users < 101
    ) {
      let newYearCharge;
      if (this.props.maximum_users < 20) {
        this.setState({ ...this.state, discountPercentage: 10 });
        newYearCharge = parseFloat(
          calculateDiscountedPrice(this.props.rate.old_rate, 10) *
            this.props.maximum_users
        ).toFixed(2);
      } else if (
        this.props.maximum_users >= 20 &&
        this.props.maximum_users < 40
      ) {
        this.setState({ ...this.state, discountPercentage: 20 });
        newYearCharge = parseFloat(
          calculateDiscountedPrice(this.props.rate.old_rate, 20) *
            this.props.maximum_users
        ).toFixed(2);
      } else if (
        this.props.maximum_users >= 40 &&
        this.props.maximum_users < 50
      ) {
        this.setState({ ...this.state, discountPercentage: 30 });
        newYearCharge = parseFloat(
          calculateDiscountedPrice(this.props.rate.old_rate, 30) *
            this.props.maximum_users
        ).toFixed(2);
      } else if (
        this.props.maximum_users >= 50 &&
        this.props.maximum_users < 60
      ) {
        this.setState({ ...this.state, discountPercentage: 40 });
        newYearCharge = parseFloat(
          calculateDiscountedPrice(this.props.rate.old_rate, 40) *
            this.props.maximum_users
        ).toFixed(2);
      } else if (
        this.props.maximum_users >= 60 &&
        this.props.maximum_users < 101
      ) {
        this.setState({ ...this.state, discountPercentage: 50 });
        newYearCharge = parseFloat(
          calculateDiscountedPrice(this.props.rate.old_rate, 50) *
            this.props.maximum_users
        ).toFixed(2);
      }
      let oldYearlyCharge = parseFloat(
        this.props.rate.old_rate * this.props.maximum_users
      ).toFixed(2);
      this.setState({
        newYearlyCharge: newYearCharge,
        oldYearlyCharge,
      });
    }
    if (this.props.location.search !== prevProps.location.search) {
      const queryParams = new URLSearchParams(this.props.location.search);
      const stepFromQuery = queryParams.get("page");
      if (stepFromQuery && stepFromQuery < 4) {
        this.setState({ step: Number(stepFromQuery) });
      } else {
        this.setState({ step: 1 });
      }
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.nextStep(tab);
    }
  }
  nextStep(ev) {
    ev.preventDefault();
    let { first_name, last_name, email, password } = this.props;
    const queryParams = new URLSearchParams(this.props.location.search);
    if (this.state.step < 3) {
      if (this.state.step === 2) {
        let model = {
          first_name: {
            value: first_name,
            key: "",
            validator: [
              "isString",
              "isNotIncludeSpecialCharacter",
              "isIncludeNumber",
              "isLessThanTwenty",
              "isNotEmpty",
            ],
          },
          last_name: {
            value: last_name,
            key: "",
            validator: [
              "isString",
              "isNotIncludeSpecialCharacter",
              "isLessThanTwenty",
              "isNotEmpty",
            ],
          },
          email: {
            value: email,
            key: "",
            validator: ["isLessThanFifty", "isEmail", "isNotEmpty"],
          },
          password: {
            value: password,
            key: "",
            validator: ["isGreaterThanSix", "isNotEmpty"],
          },
        };

        let err = checkError(model);
        if (Object.keys(err).length > 0) {
          this.props.onAddValidationError(err);
        } else {
          queryParams.set("page", this.state.step + 1);
          this.props.history.push(`?${queryParams.toString()}`);
          this.setState({ ...this.state, step: this.state.step + 1 });
        }
      } else {
        queryParams.set("page", this.state.step + 1);
        this.props.history.push(`?${queryParams.toString()}`);
        this.setState({ ...this.state, step: this.state.step + 1 });
      }
    }
  }
  previousStep() {
    if (this.state.step <= 3 && this.state.step > 1) {
      const queryParams = new URLSearchParams(this.props.location.search);
      queryParams.set("page", this.state.step - 1);
      this.props.history.push(`?${queryParams.toString()}`);
      this.setState({ ...this.state, step: this.state.step - 1 });
    }
  }
  getRate(rate, users) {
    return parseFloat(rate * users)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  sliderChangeHandler(e) {
    const { name, value } = e.target;
    this.props.onChangeField(name, value);
  }

  captureError(ev, key) {
    let error = this.props.error;
    delete error[key];
    this.props.onAddValidationError(error);
    if (ev.error && ev.error.message) {
      error[key] = ev.error.message;
      this.props.onAddValidationError(error);
    }
    if (!ev.empty) {
      this.setState({
        [key]: "value",
      });
    } else {
      this.setState({
        [key]: "",
      });
    }
    this.setState({
      update: true,
    });
  }

  render() {
    let { first_name, last_name, email, password, rate, coupon, company_name } =
      this.props;
    const { new_rate, old_rate } = rate;
    if (coupon) {
      return <LandingPage name={company_name} coupon={coupon} />;
    }
    const sliderStyle = {
      background: `linear-gradient(to right, #abe3fc 0%, #2f6fbf ${
        this.props.maximum_users - 1
      }%, rgba(48, 127, 226, 0.3) ${
        this.props.maximum_users - 1
      }%, rgba(48, 127, 226, 0.3) 100%)`,
    };
    return (
      <div className="kickOff-wrapper">
        <Row className="kickOff-wrapper-row">
          <Col className="left-container" xs="12" sm="6">
            <div className="left">
              <div className="kickOff-middle-container">
                <img src={logo} alt="unplug logo" className="kickOff-logo" />
                <div className="kickOff-left-content">
                  <div className="kickOff-heading">
                    <p>Prioritize your team’s wellbeing</p>
                    <span>
                      Gift your team the Unplug app, the perfect tool for
                      stress, burnout and increased resilience. 10-minute videos
                      to feel 10x better. Science-based and results-driven.
                    </span>
                  </div>
                  <img src={girlAtDesk} alt="" />
                  <div className="kickoff-supporting-text">
                    <p>Curious how Unplug for Business works?</p>
                    <a
                      href="mailto:corporate@unplug.com?subject=Inquiry for Unplug app team subscriptions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Book a demo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="right-container" xs="12" sm="6">
            <div className={`right-step${this.state.step}`}>
              <div className="kickOff-form-container">
                {this.state.step === 1 && (
                  <>
                    <div className="kickOff-form-header">
                      Teams Plan for 5-100 Users
                    </div>
                    <div className="kickOff-slider-body">
                      <div className="slider-container">
                        <input
                          type="range"
                          min="5"
                          max="101"
                          value={this.props.maximum_users}
                          name="maximum_users"
                          onChange={this.sliderChangeHandler}
                          style={sliderStyle}
                        />
                      </div>
                      <div className="kickOff-user-detail">
                        <span className="kickOff-user-count">
                          {this.props.maximum_users} Users
                        </span>
                        {this.props.maximum_users < 101 && (
                          <span className="kickOff-condition">
                            *including purchaser
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="kickOff-divider"></div>
                    {this.props.maximum_users < 101 ? (
                      <>
                        <div className="kickOff-pricing">
                          <div className="kickOff-price-details">
                            <div className="kickOff-price-per-user">
                              <p>Price per user</p>
                              <span>
                                Saving $
                                {old_rate && new_rate
                                  ? parseFloat(
                                      old_rate -
                                        calculateDiscountedPrice(
                                          this.props.rate.old_rate,
                                          this.state.discountPercentage
                                        )
                                    ).toFixed(2)
                                  : "N/A"}
                                /user
                              </span>
                            </div>
                            <div className="kickOff-price">
                              <p>
                                {new_rate
                                  ? `$${calculateDiscountedPrice(
                                      this.props.rate.old_rate,
                                      this.state.discountPercentage
                                    )}/year`
                                  : "N/A"}
                              </p>
                              <span>
                                {old_rate ? `$${old_rate}/year` : "N/A"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="kickOff-pricing">
                          <div className="kickOff-price-total">
                            <p className="kickOff-total">Total</p>
                            <div className="kickOff-price">
                              <p>
                                $
                                {new_rate
                                  ? this.state.newYearlyCharge
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : "N/A"}
                                /year
                              </p>
                              <span>
                                $
                                {old_rate
                                  ? this.state.oldYearlyCharge
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : "N/A"}
                                /year
                              </span>
                            </div>
                          </div>
                        </div>
                        <button
                          className="kickOff-form-button"
                          onClick={this.nextStep}
                        >
                          Proceed to Checkout
                        </button>
                        <div className="kickOff-help">
                          <span>Need help?</span>{" "}
                          <a
                            href="mailto:support@unplug.com"
                            className="underlined"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Contact us
                          </a>
                        </div>
                      </>
                    ) : (
                      <div className="kickOff-larger-team">
                        <span className="kickOff-larger-team-header">
                          Buying for a larger team?
                        </span>
                        <span className="kickOff-larger-team-sub-text">
                          If you would like to purchase coverage for more than
                          100 team members, please contact us to book a demo.
                        </span>
                        <button
                          className="kickOff-form-button margin-12"
                          onClick={() =>
                            window.open(
                              "mailto:corporate@unplug.com?subject=Inquiry for Unplug app team subscriptions",
                              "_blank"
                            )
                          }
                        >
                          <div>Contact us</div>
                        </button>
                      </div>
                    )}
                  </>
                )}
                {this.state.step === 2 && (
                  <>
                    <div className="kickOff-back">
                      <button onClick={this.previousStep}>
                        <img src={back} alt="back arrow" />
                        Back
                      </button>
                    </div>
                    <div className="kickOff-form-title-flex">
                      <span className="kickOff-normal-header-text ">
                        Teams Plan - {this.props.maximum_users} Users
                      </span>
                      <span className="kickOff-sub-header">
                        Create a login for your Unplug account
                      </span>
                    </div>
                    <form
                      onSubmit={this.nextStep}
                      className="kickOff-user-details"
                    >
                      <Row className="kickOff-form-row-full">
                        <Col className="kickOff-form-input left-col" xs={6}>
                          <div className="kickOff-label-container">
                            <span className="kickOff-label">First Name</span>
                            <span className="kickOff-error">
                              {this.props.checkIfValidationError("first_name")}
                            </span>
                          </div>

                          <input
                            type="text"
                            className={`kickOff-input ${
                              this.props.checkIfValidationError("first_name")
                                ? "kickOff-input-error"
                                : ""
                            }`}
                            placeholder="First Name"
                            name="first_name"
                            value={first_name}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col className="kickOff-form-input right-col" xs={6}>
                          <div className="kickOff-label-container">
                            <span className="kickOff-label">Last Name</span>
                            <span className="kickOff-error">
                              {this.props.checkIfValidationError("last_name")}
                            </span>
                          </div>

                          <input
                            type="text"
                            className={`kickOff-input ${
                              this.props.checkIfValidationError("last_name")
                                ? "kickOff-input-error"
                                : ""
                            }`}
                            placeholder="Last Name"
                            name="last_name"
                            value={last_name}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>
                      <Row className="kickOff-form-row-full">
                        <Col className="kickOff-form-input-full" xs={12}>
                          <div className="kickOff-label-container">
                            <span className="kickOff-label">Email</span>
                            <span className="kickOff-error">
                              {this.props.checkIfValidationError("email")}
                            </span>
                          </div>
                          <input
                            type="text"
                            className={`kickOff-input ${
                              this.props.checkIfValidationError("email")
                                ? "kickOff-input-error"
                                : ""
                            }`}
                            placeholder="Email Address"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>
                      <Row className="kickOff-form-row-full">
                        <Col className="kickOff-form-input-full" xs={12}>
                          <div className="kickOff-label-container">
                            <span className="kickOff-label">Password</span>
                            <span className="kickOff-error">
                              {this.props.checkIfValidationError("password")}
                            </span>
                          </div>
                          <input
                            className={`kickOff-input ${
                              this.props.checkIfValidationError("password")
                                ? "kickOff-input-error"
                                : ""
                            }`}
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                            type="password"
                          />
                        </Col>
                      </Row>
                      <div className="kickOff-note">
                        <span>
                          NOTE: Once you purchase the Teams Plan, your personal
                          Unplug account will be activated with an annual
                          subscription as a part of the Team. To start using
                          Unplug, simply download the app and login with your
                          credentials above.
                        </span>
                      </div>
                      <button className="kickOff-form-button" type="submit">
                        Next
                      </button>
                      <div className="kickOff-help">
                        <span>Need help?</span>{" "}
                        <a
                          href="mailto:support@unplug.com"
                          target="_blank"
                          className="underlined"
                          rel="noopener noreferrer"
                        >
                          Contact us
                        </a>
                      </div>
                    </form>
                  </>
                )}
                <AlertDismissible alertClass={"testAlert"} />
                {this.state.step === 3 && (
                  <>
                    <div className="kickOff-back">
                      <button onClick={this.previousStep}>
                        <img src={back} alt="back arrow" />
                        Back
                      </button>
                    </div>
                    <div className="kickOff-form-title-flex">
                      <span className="kickOff-normal-header-text ">
                        Teams Plan - {this.props.maximum_users} Users
                      </span>
                      <div className="kickOff-pricing">
                        <span className="kickOff-sub-header-price">
                          $
                          {this.state.newYearlyCharge
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          /year
                        </span>
                        <span className="kickOff-sub-header-price-line-through">
                          $
                          {this.state.oldYearlyCharge
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          /year
                        </span>
                      </div>
                    </div>

                    <Elements>
                      <StripeForm
                        {...this.props}
                        newYearlyCharge={this.state.newYearlyCharge}
                        oldYearlyCharge={this.state.oldYearlyCharge}
                      />
                    </Elements>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="marquee-container-slider">
          <span className="kickOff-slider-text">
            Companies that trust unplug:
          </span>
          <Marquee autoFill={true} speed={100} direction="right">
            <img
              src={brandOne}
              alt="Brands"
              ref={this.marqueeRef}
              className="marquee-container"
            />
            <img
              src={brandTwo}
              alt="Brands"
              ref={this.marqueeRef}
              className="marquee-container"
            />
          </Marquee>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamPortal)
);
