import {LOAD_RATE, SET_COUPON, UNLOAD_TEAM_PORTAL, UPDATE_FIELD_TEAM} from '../constants/types';

let defaultState = {
	email: "",
	password: "",
	name: "",
	maximum_users: 5,
	card_holder: "",
	zip_code: "",
	rate: {},
	per_year: 0,
	coupon: ""
	
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case UPDATE_FIELD_TEAM:
			return {...state, [action.key]: action.value};
		case UNLOAD_TEAM_PORTAL:
			return {};
		case LOAD_RATE:
			return {...state, rate: action.payload || {}};
		case SET_COUPON:
			return {...state, coupon: action.payload || ""};
		default:
			return state;
	}
};
