import TeamPortal from "../components/PublicModules/TeamPortal";
import Signup from "../components/Auth/Signup";
import PageLoader from "../components/Hoc/PageLoader";

export const routes = [
  {
    path: "/",
    component: PageLoader(TeamPortal),
    exact: true,
  },
  {
    path: "/sign-up",
    component: PageLoader(Signup),
    exact: true,
  },
  {
    path: "/sign-up/:guestPass",
    component: PageLoader(Signup),
    exact: true,
  },
];
