import React, { Component } from "react";

export default class Header extends Component {
  render() {
    if (this.props.inProgress) {
      return (
        <div className="adjust">
          <div className="loader" />
        </div>
      );
    }

    return <div dangerouslySetInnerHTML={{ __html: this.props.content }} />;
  }
}
