
import React, { Component } from "react";
import { connect } from "react-redux";
import { auth, common } from "../../../actions";
import { bindActionCreators } from "redux";
import {
  REGISTER_PAGE_UNLOADED,
  UPDATE_FIELD_AUTH,
} from "../../../constants/types";
import Loading from "../../Layout/Loading";
import Kickoff from "./Kickoff";
import Alert from "../../Alert";
import { withRouter } from "react-router";
import { main_app } from "../../../config/Auth";
import logo from "../../../../src/assets/images/Black-logo.png";
import phones from "../../../../src/assets/images/Phones.png";
import { Helmet } from "react-helmet";
const mapStateToProps = (state) => ({
  ...state.home,
  ...state.alert,
  ...state.auth,
  ...state.common,
  ...state.validationError,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {},
  onUnload: () => dispatch({ type: REGISTER_PAGE_UNLOADED }),
  onSubmit: () => {
    bindActionCreators(auth.registerUser, dispatch)();
  },
  onChangeField: (key, value) => {
    dispatch({ type: UPDATE_FIELD_AUTH, key, value });
    bindActionCreators(common.deleteValidationErrorAttribute, dispatch)(key);
  },
});

class Signup extends Component {
  constructor(props) {
    super(props);
    this.handleChange = (ev) => {
      let value;
      if (ev.target.type === "checkbox") {
        value = ev.target.checked;
      } else {
        value = ev.target.value.trimRight();
      }
      this.props.onChangeField(ev.target.name, value);
    };
    this.handleChangePassword = (ev) => {
      let value;
      value = ev.target.value;
      this.props.onChangeField(ev.target.name, value);
    };
    this.submitForm = () => (ev) => {
      ev.preventDefault();
      this.props.onSubmit();
    };
  }

  componentWillMount() {
    this.props.onLoad();
  }

  componentDidMount() {
    let coupon = this?.props?.location?.pathname.includes("#GUESTPASS30")
      ? "#GUESTPASS30"
      : this.props.location.hash || this?.props?.match?.params?.guestPass;

    if (coupon && !this?.props?.match?.params?.guestPass) {
      this.props.onChangeField("coupon", coupon.substr(1));
    } else {
      this.props.onChangeField("coupon", coupon);
    }
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    let {
      email,
      password,
      first_name,
      last_name,
      coupon,
      agreement,
      registerSuccessFul,
      full,
      team_name,
    } = this.props;
    if (registerSuccessFul || full) {
      return <Kickoff full={full} team_name={team_name} />;
    }
    return (
      <React.Fragment>
        {/* <Header/> */}
        <div className="wrapper coupon-wrapper d-flex">
          <div className="coupon-left">
            <div className="coupon-logo-div">
              <img src={logo} className="img-fluid" alt=""></img>
            </div>

            <div className="coupon-left-content">
              <div className="coupon-header-text text-center">
                <h2>
                  Meditation made easy to experience peace, presence &
                  positivity.
                </h2>
              </div>
              <div className="coupon-banner-img">
                <img src={phones} className="img-fluid" alt=""></img>
              </div>
            </div>
          </div>

          <div className="coupon-right ">
            <div className="coupon-form-div">
              <h3 className="text-center coupon-form-title">
                Create your unplug account
              </h3>
              <Alert alertClass="alertCoupon" />
              <div className="form-div">
                <form onSubmit={this.submitForm()}>
                  <div
                    className={`input-field ${this.props.checkIfErrorExists(
                      "coupon"
                    )}`}
                  >
                    <label>Coupon Code</label>
                    <input
                      readOnly={true}
                      className="form-control"
                      type="text"
                      name="coupon"
                      value={coupon}
                      onChange={this.handleChange}
                    />
                    <span className="alert-message">
                      {this.props.checkIfValidationError("coupon")}
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-6 ">
                      <div
                        className={`input-field ${this.props.checkIfErrorExists(
                          "first_name"
                        )}`}
                        style={{
                          width: "100%",
                          display: "inline-block",
                          marginRight: "0px",
                        }}
                      >
                        <label>First Name </label>
                        <input
                          className="form-control"
                          type="text"
                          name="first_name"
                          value={first_name}
                          onChange={this.handleChange}
                        />
                        <span className="alert-message">
                          {this.props.checkIfValidationError("first_name")}
                        </span>
                      </div>
                    </div>

                    <div className="col-6 pl-1">
                      <div
                        className={`input-field ${this.props.checkIfErrorExists(
                          "last_name"
                        )}`}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <label>Last Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="last_name"
                          value={last_name}
                          onChange={this.handleChange}
                        />
                        <span className="alert-message">
                          {this.props.checkIfValidationError("last_name")}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`input-field ${this.props.checkIfErrorExists(
                      "email"
                    )}`}
                  >
                    <label>Email</label>
                    <input
                      className="form-control"
                      type="text"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                    <span className="alert-message_email">
                      {this.props.checkIfValidationError("email")}
                    </span>
                  </div>

                  <div
                    className={`input-field ${this.props.checkIfErrorExists(
                      "password"
                    )}`}
                  >
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="Password"
                      name="password"
                      value={password}
                      onChange={this.handleChangePassword}
                    />
                    <span className="alert-message">
                      {this.props.checkIfValidationError("password")}
                    </span>
                  </div>

                  <div
                    className={`policy-section ${this.props.checkIfErrorExists(
                      "agreement"
                    )} form-check`}
                  >
                    <input
                      class="form-check-input border-0"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                      name="agreement"
                      checked={agreement}
                      onChange={this.handleChange}
                    ></input>
                    <label class="form-check-label" for="defaultCheck1">
                      By checking this box, you agree to unplug's
                    </label>
                    <a target={"_blank"} href={main_app + "terms-of-use"}>
                      <strong> Terms & Conditions</strong>
                    </a>{" "}
                    and{" "}
                    <a target={"_blank"} href={main_app + "privacy-policy"}>
                      <strong> Privacy Policy </strong>
                    </a>
                    <span className="alert-message relative-left">
                      {this.props.checkIfValidationError("agreement")}
                    </span>
                  </div>

                  <div className="coupon-buttonBig">
                    <button
                      type="submit"
                      class="btn coupon-btn-redeem  changebtnColor"
                    >
                      <Loading
                        inProgress={this.props.inProgress}
                        content={"Redeem your gift"}
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="contact-link-wrapper">
              <div className="contact-link text-center">
                <p>
                  Need help? &nbsp;
                  <a href="mailto:support@unplug.com">
                    <u>Contact us</u>
                  </a>
                </p>
              </div>
            </div>

            {/* <div className="signIn text-center">
								<p>Need Help? 
									<a href={liveapiURL + "/login"}>Contact us</a>
								</p>
						</div>	 */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
